import React, { useEffect, useState, useRef } from "react";
import { getCuriousityRoverManifest, getCuriousityRoverPhotos } from "../managers/apimanager";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import SimpleImageSlider from "react-simple-image-slider";
import ClipLoader from "react-spinners/ClipLoader";
import 'react-awesome-slider/dist/styles.css';
import ImageSlider from "./customimageslider";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

class CuriosityRover extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            availableDates: [],
            photos: ["https://upload.wikimedia.org/wikipedia/commons/0/02/OSIRIS_Mars_true_color.jpg"],
            date: null,
            loading: true,
            dropDownRef: null,
            expand:true
        }
        this.dateSelected = this.dateSelected.bind(this)
    }
    async dateSelected(e) {
        this.setState({ loading: true }, async () => {
            console.log(e)
            var date = e.label
            var photosAPI = await getCuriousityRoverPhotos(date)
            var photos = []
            for (var photo of photosAPI.photos) {
                photos.push(photo.img_src)
            }
            var photoObj = {}
            for(var photo of photos){
                if(photoObj[photo]) photoObj[photo] ++
                else photoObj[photo] = 1
            }
            console.log(photoObj)
            this.setState({ date, photos, loading: false })
        })
    }
    findCommonElements3(arr1, arr2) {
        return arr1.some(item => arr2.includes(item))
    }
    parseManifest(manifest) {
        var cameras = ['NAVCAM']
        var dates = []
        for (var photos of manifest.photo_manifest.photos) {
            if (this.findCommonElements3(cameras, photos.cameras)) dates.push(photos.earth_date)
        }
        dates.reverse()
        this.setState({ availableDates: dates, loading: false })
    }
    async componentDidMount() {
        var manifest = await getCuriousityRoverManifest()
        this.parseManifest(manifest)
    }
    render() {
        console.log('Rendered', this.state)
        console.log(window)
        return (
            <div style={styles.container}>
                <span style={styles.header}>Select a Date, You Might Even See Curiosity's Selfies </span>
                {this.state.loading ?
                    <ClipLoader size={50} /> :
                    <div >
                        <Dropdown value={this.state.date} options={this.state.availableDates} onChange={this.dateSelected} placeholder="Select a date." />
                        <ImageSlider images={this.state.photos} />
                    </div>
                }




            </div>
        )
    }
}

const styles = {
    expand:{
        display:'flex',
        whiteSpace: 'nowrap',
        textAlign:'right',
        width:'100%'
    },
    header: {
        display: "flex",
        textAlign: 'left',
        fontFamily: "Verdana, sans-serif",
        marginBottom: '10px',
        marginTop: '10px',
        fontSize: '20px',
        paddingLeft: '10px',
    },
    container: {
        width: '100%',
        position: 'relative'
    }
}

export default CuriosityRover